export default defineNuxtRouteMiddleware(async (to) => {
  const { deviceUri, problemUri } = to.params;

  const data = await getDeviceProblems(deviceUri as string);

  const matchedProblem = data.find((problem) => problem.uriId === problemUri);

  if (!matchedProblem) {
    return navigateTo("/not-found");
  }

  const problemState = useState("problem", () => matchedProblem);
  problemState.value = matchedProblem;
});
